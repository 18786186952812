<template>
  <section class="main">
    <div class="question" v-for="(item, index) in tikuQuestions" :key="index">
      <questionViewWidget
        :tikuQuestion="item"
        :showAnswer="false"
        :showQuestion="true"
        :qIndex="index + 1"
      ></questionViewWidget>
      <br />
    </div>
    <h2 style="margin: 20px 0">【参考解析】</h2>
    <div class="question" v-for="(item, index) in tikuQuestions" :key="item.id">
      <questionViewWidget
        :tikuQuestion="item"
        :showAnswer="true"
        :showQuestion="false"
        :qIndex="index + 1"
      ></questionViewWidget>
      <br />
    </div>
  </section>
</template>

<script>
import questionViewWidget from "@/component/questionViewWidget";
import { tikuApi } from "@/api/tikuApi";

export default {
  data() {
    return {
      showLatex: 0,
      subjectCourseId: "0",
      ids: "0",
      tikuQuestions: [],
    };
  },
  components: { questionViewWidget },
  mounted() {
    console.log(process.env.VUE_APP_ENV);
    const ids = this.$route.query.ids;
    const subjectCourseId = this.$route.query.subjectCourseId;
    this.ids = ids;
    this.subjectCourseId = subjectCourseId;
    this.onGet();
  },
  methods: {
    onGet() {
      tikuApi.batchGet(this.subjectCourseId, this.ids).then((ret) => {
        let { code, data: items } = ret;
        console.log("batchGet", ret);
        if (code == 0 && items) {
          this.tikuQuestions = items;
        }
      });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 700px;
  text-align: left;
}
</style>

<style>
.latex-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.latex-inline {
  vertical-align: middle;
}
p {
  margin: 0;
}
</style>
